import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

function ResponsiveAppBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Logo e título */}
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/feescrita.jpg" // Substitua pelo caminho correto da sua logo
              alt="Logo Fé Escrita"
              style={{ width: '80px', height: '80px', marginRight: '10px', maxWidth: '100%' }}
            />
            <Typography
              variant="h6"
              component={Link}
              to="/"
              style={{ color: 'inherit', textDecoration: 'none', fontWeight: 'bold', fontSize: '2.5rem' }}
            >
              Fé Escrita
            </Typography>
          </Box>

          {/* Botões para telas maiores */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '10px' }}>
            <Button color="inherit" href="https://www.youtube.com/@CoracaoCristao-go7ms" target="_blank" rel="noopener noreferrer">
              Coração Cristão
            </Button>
            <Button color="inherit" href="https://cmaonline.com.br/" target="_blank" rel="noopener noreferrer">
              Corpo, Mente & Alma
            </Button>
            <Button color="inherit" component={Link} to="/about">
              Sobre
            </Button>
            <Button color="inherit" component={Link} to="/privacy-policy">
              Política de Privacidade
            </Button>
          </Box>

          {/* Botão para abrir o menu móvel */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer para o menu móvel */}
      <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: '#1976d2', // Fundo azul
              color: 'white', // Texto branco
            },
          }}
        >
          <List>
            <ListItem
              button
              component="a"
              href="https://www.youtube.com/@CoracaoCristao-go7ms"
              onClick={handleDrawerClose}
              sx={{
                '& .MuiTypography-root': {
                  color: 'white', // Garantir texto branco
                },
                '&:hover': {
                  backgroundColor: '#1565c0', // Destaque ao passar o mouse
                },
              }}
            >
              <ListItemText primary="Coração Cristão" />
            </ListItem>
            <ListItem
              button
              component="a"
              href="https://cmaonline.com.br/"
              onClick={handleDrawerClose}
              sx={{
                '& .MuiTypography-root': {
                  color: 'white', // Garantir texto branco
                },
                '&:hover': {
                  backgroundColor: '#1565c0', // Destaque ao passar o mouse
                },
              }}
            >
              <ListItemText primary="Corpo, Mente & Alma" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/about"
              onClick={handleDrawerClose}
              sx={{
                '& .MuiTypography-root': {
                  color: 'white', // Garantir texto branco
                },
                '&:hover': {
                  backgroundColor: '#1565c0', // Destaque ao passar o mouse
                },
              }}
            >
              <ListItemText primary="Sobre" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/privacy-policy"
              onClick={handleDrawerClose}
              sx={{
                '& .MuiTypography-root': {
                  color: 'white', // Garantir texto branco
                },
                '&:hover': {
                  backgroundColor: '#1565c0', // Destaque ao passar o mouse
                },
              }}
            >
              <ListItemText primary="Política de Privacidade" />
            </ListItem>
          </List>
        </Drawer>
    </>
  );
}

export default ResponsiveAppBar;
